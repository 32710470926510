import React from 'react'

export default function CheckMarkSimpleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
    >
      <path
        d="M11.7257 0.812782L6.55361 6.35487L4.58214 4.13678C4.10059 3.59499 3.27099 3.54615 2.7292 4.02771L1.15958 5.42281C0.617783 5.90437 0.568949 6.73396 1.05051 7.27576L5.5111 12.2943C6.02475 12.8722 6.92414 12.8832 7.45167 12.3179L15.1801 4.03654C15.6746 3.50659 15.6459 2.67606 15.116 2.1815L13.5807 0.748713C13.0507 0.254148 12.2202 0.282832 11.7257 0.812782Z"
        fill="#28CBE1"
        stroke="#28CBE1"
        strokeWidth="0.525"
      />
    </svg>
  )
}
