import _ from 'lodash'
import React, { useState } from 'react'
import closeCrossIcon from '../assets/images/close-cross.svg'
import infoIcon from '../assets/images/info-icon.svg'
import {
  ComparisonData,
  FeatureCategory,
  FeatureValue,
  PricingTier,
  pricingFeaturesConfig,
} from '../pages/pricing'
import { KeysRequired } from '../typeUtils'
import CheckMarkIcon from './CheckMarkIcon'
import {
  ComparisonTable,
  SpacerRow,
  TableCell,
  TableRow,
} from './ComparisonTable'
import Select from './Select'
import Tooltip from './Tooltip'
import Box from './primitives/Box'
import Row from './primitives/Row'
import Button from './system/Button'

export type PricingTierWithFeatures = PricingTier & {
  comparisonData: KeysRequired<ComparisonData, 'features'>
}

function isPricingTierWithFeatures(
  tier: PricingTier,
): tier is PricingTierWithFeatures {
  return tier.comparisonData?.features !== undefined
}

const PricingComparisonTable = ({ tiers }: { tiers: PricingTier[] }) => {
  const tiersWithFeatures = tiers.filter(isPricingTierWithFeatures)
  const [selectedTier, setSelectedTier] = useState<string>(
    tiersWithFeatures.find(t => t.cardStyle === 'highlight')!.title,
  )
  const tierOptions = tiersWithFeatures.map(t => ({
    label: t.comparisonData.title ?? t.title,
    value: t.title,
  }))
  const selector = (
    <Box display={{ xs: 'block', lg: 'none' }} marginX={16}>
      <Select
        placeholder="Change plan"
        isSearchable={false}
        defaultValue={{ label: selectedTier, value: selectedTier }}
        options={tierOptions}
        onChange={option => (option ? setSelectedTier(option.value) : null)}
      />
    </Box>
  )
  return (
    <ComparisonTable title="Compare all features" selector={selector}>
      <TierHeaderRow tiers={tiersWithFeatures} selectedTier={selectedTier} />
      <CategoryHeaderRow
        withSpacerRow={false}
        categoryName="Features"
        tiers={tiersWithFeatures}
        selectedTier={selectedTier}
      />
      <CategoryRows
        category="features"
        tiers={tiersWithFeatures}
        selectedTier={selectedTier}
      />
      <CategoryHeaderRow
        withSpacerRow={true}
        categoryName="Integrations"
        tiers={tiersWithFeatures}
        selectedTier={selectedTier}
      />
      <CategoryRows
        category="integrations"
        tiers={tiersWithFeatures}
        selectedTier={selectedTier}
      />
      <SpacerRow
        height={32}
        columns={tiersWithFeatures}
        selectedColumn={selectedTier}
      />
    </ComparisonTable>
  )
}

function TierHeaderRow({
  tiers,
  selectedTier,
}: {
  tiers: PricingTier[]
  selectedTier: string
}) {
  return (
    <TableRow
      columns={tiers}
      selectedColumn={selectedTier}
      variant="tierHeader"
    >
      <TableCell isSpacer />
      {tiers.map(tier => (
        <TableCell key={`${tier.title}`} alignItems="flex-start">
          <Box font="h4" fontWeight={700} color="blue">
            {tier.comparisonData?.title ?? tier.title}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  )
}

function CategoryHeaderRow({
  withSpacerRow,
  categoryName,
  tiers,
  selectedTier,
}: {
  withSpacerRow: boolean
  categoryName: string
  tiers: PricingTier[]
  selectedTier: string
}) {
  return (
    <>
      {withSpacerRow ? (
        <SpacerRow height={62} columns={tiers} selectedColumn={selectedTier} />
      ) : null}
      <TableRow columns={tiers} selectedColumn={selectedTier} variant="header">
        <TableCell>
          <Box fontWeight={700}>{categoryName}</Box>
        </TableCell>
        {_.range(tiers.length).map(i => (
          <TableCell key={`${categoryName}-${i}`} />
        ))}
      </TableRow>
    </>
  )
}

function CategoryRows({
  category,
  tiers,
  selectedTier,
}: {
  category: FeatureCategory
  tiers: PricingTierWithFeatures[]
  selectedTier: string
}) {
  const categoryFeatures = pricingFeaturesConfig.filter(
    feature => feature.category === category,
  )
  return (
    <>
      {categoryFeatures.map((feature, i) => {
        const { key, label } = feature
        const rowVariant = i % 2 == 0 ? 'normal' : 'highlight'
        const tooltip = 'tooltip' in feature ? feature.tooltip : undefined
        const url = 'url' in feature ? feature.url : undefined
        return (
          <TableRow
            key={key}
            columns={tiers}
            selectedColumn={selectedTier}
            variant={rowVariant}
          >
            <TableCell>
              <Row width="100%" space={4} alignItems="center">
                <Box>
                  {url ? (
                    <Button variant="basicLink" href={url}>
                      {label}
                    </Button>
                  ) : (
                    label
                  )}
                </Box>
                {tooltip ? (
                  <Box marginTop={2} marginLeft="auto">
                    <Tooltip title={tooltip}>
                      <Box.img src={infoIcon} />
                    </Tooltip>
                  </Box>
                ) : null}
              </Row>
            </TableCell>
            {tiers.map(tier => {
              return (
                <FeatureValueCell
                  key={`${key} ${tier.title}`}
                  featureValue={tier.comparisonData.features[key]}
                />
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}

function FeatureValueCell({ featureValue }: { featureValue?: FeatureValue }) {
  const isString = typeof featureValue === 'string'
  return (
    <TableCell>
      {isString ? (
        featureValue
      ) : featureValue ? (
        <CheckMarkIcon />
      ) : (
        <Box.img width={12} height={12} src={closeCrossIcon} />
      )}
    </TableCell>
  )
}

export default PricingComparisonTable
