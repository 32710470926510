import React, { ReactNode } from 'react'
import Badge from '../assets/images/most-popular-badge.svg'
import { BillingCycle, PricingCurrency, PricingTier } from '../pages/pricing'
import { ThemeFontSize, ThemeLineHeight } from '../styles/theme'
import CheckMarkSimpleIcon from './CheckMarkSimpleIcon'
import Box from './primitives/Box'
import Row from './primitives/Row'
import Stack from './primitives/Stack'
import Button from './system/Button'

const PricingCard = (props: {
  tier: PricingTier
  currency?: PricingCurrency
  billingCycle: BillingCycle
}) => {
  const { tier, currency, billingCycle } = props
  const isHighlight = tier.cardStyle === 'highlight'
  const isPlain = tier.cardStyle === 'plain'
  const isModule = tier.cardStyle === 'module'
  const background = isHighlight
    ? 'linear-gradient(180deg, #6A57FF 0%, #4736D3 100%)'
    : isPlain
    ? 'white'
    : 'linear-gradient(180deg, #F5F3FF 0%, #EBE9FF 100%)'
  return (
    <Stack
      space={0}
      position="relative"
      alignItems="center"
      width="100%"
      // Hack to align highlight card without affecting others
      height={isHighlight ? '100%' : undefined}
    >
      {tier.showBadge ? <RibbonBadge /> : null}
      <Stack
        height="100%"
        width="100%"
        position="relative"
        paddingY={32}
        paddingX={24}
        borderRadius={24}
        color={isHighlight ? 'blue50' : 'black'}
        textAlign={{ xs: 'center', md: 'left' }}
        borderColor={isHighlight ? 'purple' : 'gray200'}
        backgroundImage={background}
        backgroundColor="white"
        boxShadow={isPlain ? 'small' : 'none'}
      >
        {isModule ? (
          <>
            <TierTitle tier={tier} />
            <Box marginTop={24}>
              <TierDescription tier={tier} />
            </Box>
            <TierCta tier={tier} />
          </>
        ) : (
          <Box
            display="flex"
            height="100%"
            flexDirection={{
              xs: 'column',
              md: 'row',
              lg: isHighlight ? 'column' : 'row',
            }}
          >
            <Box
              display="flex"
              width="100%"
              data-testid="trololo"
              height={{
                xs: '100%',
                md: 'auto',
                lg: isHighlight ? 'auto' : '100%',
              }}
              flexDirection="column"
            >
              <TierTitle tier={tier} />
              <Box marginBottom={{ xs: 0, md: 24, lg: isHighlight ? 0 : 24 }}>
                <TierPrice
                  tier={tier}
                  currency={currency}
                  billingCycle={billingCycle}
                />
              </Box>
              <Box
                display={{
                  xs: 'none',
                  md: 'block',
                  lg: isHighlight ? 'none' : 'block',
                }}
                marginTop="auto"
              >
                <TierCta tier={tier} />
              </Box>
            </Box>
            <Box
              width="100%"
              marginLeft={{ xs: 0, md: 24, lg: isHighlight ? 0 : 24 }}
              paddingLeft={{ xs: 0, md: 24, lg: isHighlight ? 0 : 24 }}
              marginTop={{ xs: 24, md: 0, lg: isHighlight ? 'auto' : 0 }}
              borderLeftStyle="solid"
              // @ts-ignore this works
              borderLeftWidth={{ xs: 0, md: 1, lg: isHighlight ? 0 : 1 }}
              borderLeftColor="gray200"
            >
              <TierDescription tier={tier} />
              <Box
                display={{
                  xs: 'block',
                  md: 'none',
                  lg: isHighlight ? 'block' : 'none',
                }}
              >
                <TierCta tier={tier} />
              </Box>
            </Box>
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

const TierTitle = (props: { tier: PricingTier }) => {
  const { tier } = props
  const color =
    tier.cardStyle === 'highlight'
      ? 'blue50'
      : tier.titleBackground
      ? 'transparent'
      : 'black'
  return (
    <Box.h2
      font="h5"
      textAlign="left"
      color={color}
      backgroundImage={tier.titleBackground ?? 'none'}
      css={`
        background-clip: text;
      `}
    >
      {tier.title}
    </Box.h2>
  )
}

export const TierPrice = (props: {
  tier: PricingTier
  currency?: PricingCurrency
  billingCycle: BillingCycle
}) => {
  const { tier, currency, billingCycle } = props
  const {
    priceMonthly,
    priceAnnually,
    priceDescriptionMonthly,
    priceDescriptionAnnually,
  } = tier
  const price =
    billingCycle === BillingCycle.Annually ? priceAnnually : priceMonthly
  const priceDescription =
    billingCycle === BillingCycle.Annually
      ? priceDescriptionAnnually
      : priceDescriptionMonthly
  const hasPrice = Number.isInteger(price)
  return (
    <Row marginTop={24}>
      {hasPrice && currency === 'USD' ? <CurrencySign>$</CurrencySign> : null}
      {hasPrice ? (
        <Box.span
          font="h5"
          fontSize={70 as ThemeFontSize}
          lineHeight={'100%' as ThemeLineHeight}
        >
          {price}
        </Box.span>
      ) : null}
      {hasPrice && currency === 'EUR' ? <CurrencySign>€</CurrencySign> : null}
      <Box
        font="small"
        fontSize={hasPrice ? 14 : 16}
        marginLeft={hasPrice ? 16 : 0}
        maxWidth={160}
        textAlign="left"
      >
        {priceDescription}
      </Box>
    </Row>
  )
}

const TierCta = (props: { tier: PricingTier }) => {
  const { callToAction } = props.tier
  return (
    <Button
      href={callToAction.url}
      variant={callToAction.buttonVariant}
      size="large"
      width="100%"
      marginTop="auto"
      marginX="auto"
      fontSize={{ xs: 20, md: 18 }}
    >
      {callToAction.label}
    </Button>
  )
}

const TierDescription = (props: { tier: PricingTier }) => {
  const { description, cardStyle, perksDescription, perks } = props.tier
  return (
    <>
      <Box
        font="large"
        lineHeight="140%"
        textAlign="left"
        // Align module perks lists with each other
        minHeight={{ xs: 0, lg: cardStyle === 'module' ? 56 : 0 }}
        maxWidth={{ xs: '100%', md: 320, lg: '100%' }}
      >
        {description}
      </Box>
      <Stack
        space={16}
        marginTop={24}
        marginBottom={24}
        maxWidth={400}
        color={cardStyle === 'highlight' ? 'blue50' : 'black'}
      >
        {perksDescription ? <Perk>{perksDescription}</Perk> : null}
        {perks.map(perk => {
          return (
            <Perk key={perk}>
              <CheckMarkSimpleIcon />
              <Box marginLeft={16}>{perk}</Box>
            </Perk>
          )
        })}
      </Stack>
    </>
  )
}

function CurrencySign(props: { children: ReactNode }) {
  return (
    <Box.span
      font="h5"
      fontSize={32}
      lineHeight={'100%' as ThemeLineHeight}
      display="inline-block"
      position="relative"
      top={0}
    >
      {props.children}
    </Box.span>
  )
}

function Perk(props: { children: ReactNode }) {
  return (
    <Row
      font="textLabel"
      alignItems="center"
      textAlign="left"
      fontWeight={400}
      fontSize={16}
    >
      {props.children}
    </Row>
  )
}

function RibbonBadge() {
  // Note: Wrapped img in box because Box.img does not support responsive sizing
  return (
    <Box
      position="absolute"
      right={{ xs: -37, md: -38 }}
      top={{ xs: -36, md: -30 }}
      width={{ xs: 184, md: 165 }}
      height={{ xs: 193, md: 157 }}
      zIndex={1}
    >
      <img src={Badge} width="100%" height="100%" />
    </Box>
  )
}

export default PricingCard
