import React from 'react'
import FAQWrapper from './FAQWrapper'

const FAQ = () => {
  return (
    <FAQWrapper>
      <details>
        <summary>Do I need a credit card to start a free trial?</summary>
        <p>
          No, you don’t need a credit card to start your free trial. When you’re
          installing Swarmia from the{' '}
          <a href="https://github.com/marketplace/swarmia">
            GitHub Marketplace
          </a>{' '}
          you might see an Order Summary page asking for your payment
          information. If this happens, you can click on the{' '}
          <b>complete order and begin installation</b> button without entering
          your payment information.
        </p>
      </details>
      <details>
        <summary>
          How do you determine the number of developers for billing?
        </summary>
        <p>
          If you purchase through Stripe, the number of developers will be based
          on the number of people you’ve added to teams in Swarmia.
        </p>
        <p>
          If you buy an annual subscription from our sales team, the number of
          developers can be based on either Swarmia team members or active
          contributors, (i.e. people who’ve committed code to connected
          repositories in the past 30 days.)
        </p>
      </details>
      <details>
        <summary>What’s included in the free plan?</summary>
        <p>
          The free plan contains all the features and functionality in the
          standard plan. It’s available for companies with fewer than 10
          software developers.
        </p>
      </details>
      <details>
        <summary>Do you offer any discounts for startups?</summary>
        <p>
          Startups affiliated with one of the partners in the Swarmia startup
          program get a 50% discount for their first year.
        </p>
        <p>
          Read more about <a href="/startups/">the startup program</a> .
        </p>
      </details>
      <details>
        <summary>
          Can I try Swarmia without connecting my organization’s data?
        </summary>
        <p>
          If you’d like to see Swarmia in action without connecting your own
          data, contact your account manager or email us at hello@swarmia.com to
          get access to a Sandbox account.
        </p>
        <p>
          Alternatively, you can <a href="/demo/">schedule a demo</a> with one
          of our product experts.
        </p>
      </details>
    </FAQWrapper>
  )
}

export default FAQ
