import React, { ReactNode } from 'react'
import MetaImage from '../assets/images/pricing-meta.png'
import BestDealBadge from '../components/BestDealBadge'
import G2AndCustomerLogos from '../components/G2AndCustomerLogos'
import Layout from '../components/Layout'
import PricingCalculator from '../components/PricingCalculator'
import PricingCard, { TierPrice } from '../components/PricingCard'
import PricingComparisonTable from '../components/PricingComparisonTable'
import FAQ from '../components/PricingFAQ'
import Switch from '../components/Switch'
import CTABlock from '../components/blocks/CTABlock'
import LeadBlock from '../components/blocks/LeadBlock'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Br from '../components/system/Br'
import Button, { Variant } from '../components/system/Button'
import { useCurrency } from '../regionUtils'
import { responsiveScale } from '../styles/helpers'
import { SpaceScaleValue } from '../styles/stylePropTypes'

type FeatureConfig = {
  key: string
  label: string
  category: 'info' | 'features' | 'integrations'
  tooltip?: string
  url?: string
}

export const FREE_PLAN_MAX_DEVS = 9

export const pricingFeaturesConfig = [
  // Features
  {
    key: 'investmentBalance',
    label: 'Investment balance',
    category: 'features',
    tooltip:
      'Understand where engineering time goes and intentionally manage the balance between new feature work, maintenance, and more.',
    url: '/product/investment-balance/',
  },
  {
    key: 'capex',
    label: 'Software capitalization',
    category: 'features',
    tooltip:
      'Build flexible software cost capitalization reports by blending data from Swarmia with team and salary information.',
    url: '/product/software-capitalization/',
  },
  {
    key: 'initiatives',
    label: 'Initiatives',
    category: 'features',
    tooltip:
      'Stay on top of strategic cross-team initiatives and predictably deliver on agreed business outcomes.',
    url: '/product/initiatives/',
  },
  {
    key: 'workLog',
    label: 'Work log',
    category: 'features',
    tooltip:
      'Visualize engineering work, identify teamwork anti-patterns, and unblock the flow.',
    url: '/product/work-log/',
  },
  {
    key: 'workingAgreements',
    label: 'Working agreements',
    category: 'features',
    tooltip: 'Set numeric targets and adopt healthy habits as a team.',
    url: '/product/working-agreements/',
  },
  {
    key: 'slackFeedback',
    label: 'Slack notifications',
    category: 'features',
    tooltip:
      'Speed up development workflows with a two-way integration between GitHub and Slack.',
    url: '/github-slack-integration/',
  },
  {
    key: 'dora',
    label: 'Metrics, including DORA',
    category: 'features',
    tooltip:
      'Measure change lead time, deployment frequency, change failure rate, and mean time to recovery.',
    url: '/dora-metrics/',
  },
  {
    key: 'prAnalytics',
    label: 'Pull requests',
    category: 'features',
    tooltip:
      'Continuously improve your team’s PR metrics based on real-time data.',
    url: '/github-reporting/',
  },
  {
    key: 'sprints',
    label: 'Sprints',
    category: 'features',
    tooltip: 'Improve sprint planning based on historical data.',
    url: '/product/sprints/',
  },
  {
    key: 'developerOverview',
    label: 'Developer overview',
    category: 'features',
    tooltip: 'See what each software engineer has been working on.',
    url: '/product/developer-overview/',
  },
  {
    key: 'dataCloud',
    label: 'Data cloud',
    category: 'features',
    tooltip: 'Export data from Swarmia to your data warehouse of choice.',
    url: '/product/data-platform/',
  },
  {
    key: 'ciInsights',
    label: 'CI visibility',
    category: 'features',
    tooltip: 'Reduce CI wait times and costs with detailed CI/CD insights.',
    url: '/product/ci-cd-insights/',
  },
  {
    key: 'surveys',
    label: 'Surveys',
    category: 'features',
    tooltip:
      'Complement engineering metrics with feedback from your developers.',
    url: '/product/developer-experience-surveys/',
  },

  // Integrations
  {
    key: 'googleSSO',
    label: 'Google Single Sign-On',
    category: 'integrations',
  },
  { key: 'oktaSSO', label: 'Okta Single Sign-On', category: 'integrations' },
  { key: 'slack', label: 'Slack', category: 'integrations' },
  { key: 'github', label: 'GitHub', category: 'integrations' },
  {
    key: 'githubEnterpriseCloud',
    label: 'GitHub Enterprise Cloud',
    category: 'integrations',
  },
  { key: 'jira', label: 'Jira', category: 'integrations' },
  { key: 'linear', label: 'Linear', category: 'integrations' },
  { key: 'githubActions', label: 'GitHub Actions', category: 'integrations' },
  {
    key: 'githubEnterpriseServer',
    label: 'GitHub Enterprise Server',
    category: 'integrations',
  },
  { key: 'jiraOnPrem', label: 'Jira Server', category: 'integrations' },
] as const satisfies readonly FeatureConfig[]

export type FeatureCategory = (typeof pricingFeaturesConfig)[number]['category']
export type FeatureKey = (typeof pricingFeaturesConfig)[number]['key']
export type FeatureValue = boolean | string // true/false/explanation

export type ComparisonData = {
  title?: string
  features?: Partial<Record<FeatureKey, FeatureValue>>
}
export interface PricingTier {
  title: string
  description: string
  cardStyle: 'module' | 'highlight' | 'plain'
  titleBackground?: string
  priceMonthly: string | number
  priceAnnually: string | number
  priceDescriptionMonthly: string
  priceDescriptionAnnually: string
  perksDescription?: string
  perks: string[]
  callToAction: { label: string; url: string; buttonVariant: Variant }
  showBadge?: boolean
  comparisonData?: ComparisonData
}

const pricingTiers: PricingTier[] = [
  {
    title: 'Business outcomes',
    description: 'Align engineering with the business',
    cardStyle: 'module',
    titleBackground: 'linear-gradient(90deg, #df5685 0%, #6252e2 100%)',
    priceAnnually: 20,
    priceMonthly: 25,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perks: [
      'Investment balance',
      'Software capitalization',
      'Initiatives',
      'Work log',
    ],
    callToAction: {
      label: 'Get a demo',
      url: '/demo/',
      buttonVariant: 'primary',
    },
    comparisonData: {
      features: {
        // features
        investmentBalance: true,
        capex: true,
        initiatives: true,
        workLog: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        jira: true,
        linear: true,
        oktaSSO: true,
        googleSSO: true,
      },
    },
  },
  {
    title: 'Developer productivity',
    description: 'Speed up feature delivery',
    cardStyle: 'module',
    titleBackground: 'linear-gradient(90deg, #6252e2 0%, #28cbe1 100%)',
    priceAnnually: 20,
    priceMonthly: 25,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perks: [
      'Metrics, including DORA',
      'Pull requests & CI insights',
      'Data cloud',
      'Sprints',
    ],
    callToAction: {
      label: 'Start free trial',
      url: 'https://app.swarmia.com/signup/',
      buttonVariant: 'primary',
    },
    comparisonData: {
      features: {
        // features
        workingAgreements: true,
        slackFeedback: true,
        dora: true,
        prAnalytics: true,
        sprints: true,
        developerOverview: true,
        dataCloud: true,
        ciInsights: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        jira: true,
        linear: true,
        oktaSSO: true,
        googleSSO: true,
        githubActions: true,
        slack: true,
      },
    },
  },
  {
    title: 'Developer experience',
    description: 'Get actionable feedback from engineers',
    cardStyle: 'module',
    titleBackground: 'linear-gradient(90deg, #2ebcb3 0%, #2c86da 100%)',
    priceAnnually: 20,
    priceMonthly: 25,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perks: [
      'Developer experience surveys',
      'Survey reminders on Slack',
      'Survey benchmarks',
    ],
    callToAction: {
      label: 'Get a demo',
      url: '/demo/',
      buttonVariant: 'primary',
    },
    comparisonData: {
      features: {
        // features
        surveys: true,

        // integrations
        oktaSSO: true,
        googleSSO: true,
        slack: true,
      },
    },
  },
  {
    title: 'Standard',
    description: 'Drive organization-wide engineering effectiveness',
    cardStyle: 'highlight',
    showBadge: true,
    priceAnnually: 39,
    priceMonthly: 45,
    priceDescriptionMonthly: 'per developer per month',
    priceDescriptionAnnually: 'per developer per month, billed annually',
    perksDescription: 'Includes all three modules',
    perks: [
      'Business outcomes',
      'Developer productivity',
      'Developer experience',
    ],
    callToAction: {
      label: 'Get a demo',
      url: '/demo/',
      buttonVariant: 'secondary',
    },
    comparisonData: {
      title: 'Standard / Enterprise',
      features: {
        // features
        investmentBalance: true,
        capex: true,
        initiatives: true,
        workLog: true,
        workingAgreements: true,
        slackFeedback: true,
        dora: true,
        prAnalytics: true,
        sprints: true,
        developerOverview: true,
        dataCloud: true,
        ciInsights: true,
        surveys: true,

        // integrations
        github: true,
        githubEnterpriseCloud: true,
        jira: true,
        linear: true,
        oktaSSO: true,
        googleSSO: true,
        githubActions: true,
        slack: true,
        githubEnterpriseServer: 'Enterprise',
        jiraOnPrem: 'Enterprise',
      },
    },
  },
  {
    title: 'Free',
    description: 'Great for small startups',
    cardStyle: 'plain',
    priceAnnually: 0,
    priceMonthly: 0,
    priceDescriptionMonthly: 'Free for companies with up to 9 developers',
    priceDescriptionAnnually: 'Free for companies with up to 9 developers',
    perks: ['Access all modules', 'Upgrade at any time'],
    callToAction: {
      label: 'Sign up for free',
      url: 'https://app.swarmia.com/signup/',
      buttonVariant: 'primary',
    },
  },
  {
    title: 'Enterprise',
    cardStyle: 'plain',
    description: 'Scale with confidence',
    priceMonthly: 'Contact us',
    priceAnnually: 'Contact us',
    priceDescriptionMonthly: 'Request a quote',
    priceDescriptionAnnually: 'Request a quote',
    perksDescription: 'Includes everything in Standard, plus…',
    perks: ['On-premise integrations', 'Volume discounts'],
    callToAction: {
      label: 'Contact sales',
      url: '/demo/',
      buttonVariant: 'dark',
    },
  },
]

export type PricingCurrency = 'EUR' | 'USD'

export enum BillingCycle {
  Annually = 'Annually',
  Monthly = 'Monthly',
}

export const PricingPageInternal = ({
  currency,
}: {
  currency: PricingCurrency
}) => {
  const [billingCycle, setBillingCycle] = React.useState<BillingCycle>(
    BillingCycle.Annually,
  )

  return (
    <Layout
      title="Pricing"
      variant="light"
      isNew
      description="Explore Swarmia pricing options and find a plan that works for your software engineering organization. Free plan for startups available."
      metaImage={MetaImage}
    >
      <Box paddingTop={{ xs: 92, sm: 64 }} paddingBottom={16}>
        <Box.h1 font="h2" textAlign="center" paddingX={{ xs: 20, md: 36 }}>
          Pricing & plans
        </Box.h1>
      </Box>
      <Box paddingBottom={16}>
        <HeaderBillingCycleSelector
          billingCycle={billingCycle}
          setBillingCycle={setBillingCycle}
        />
      </Box>
      <Stack maxWidth={{ xs: 648, md: 1400 }} marginX="auto">
        <PricingCardContainer currency={currency} billingCycle={billingCycle} />
        <Box>
          <Box
            marginTop={64}
            marginLeft={{ xs: 16, sm: 48 }}
            marginRight={{ xs: 16, sm: 48 }}
          >
            <PricingCalculator
              tiers={pricingTiers}
              currency={currency}
              billingCycle={billingCycle}
              setBillingCycle={setBillingCycle}
            />
          </Box>
          <Box marginY={64} marginX={{ xs: 0, sm: 48 }}>
            <PricingComparisonTable tiers={pricingTiers} />
          </Box>
        </Box>
      </Stack>
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <LeadBlock
        heading={
          <>
            Trusted by effective <Br />
            engineering organizations
          </>
        }
        link={{ title: 'Read customer stories', href: '/customers/' }}
      />
      <Box paddingTop={responsiveScale(32)} paddingBottom={responsiveScale(92)}>
        <G2AndCustomerLogos hideTitle />
      </Box>
      <CTABlock />
    </Layout>
  )
}

const HeaderBillingCycleSelector = (props: {
  billingCycle: BillingCycle
  setBillingCycle: (billingCycle: BillingCycle) => void
}) => {
  const isAnnual = props.billingCycle === BillingCycle.Annually
  return (
    <Row
      space={12}
      justifyContent="center"
      alignItems="center"
      fontSize={18}
      lineHeight="120%"
    >
      <Button
        variant="plain"
        font="normalLabel"
        color={isAnnual ? 'black400' : 'black900'}
        onClick={() => props.setBillingCycle(BillingCycle.Monthly)}
      >
        Billed monthly
      </Button>
      <Switch
        checked={isAnnual}
        onChange={() =>
          props.setBillingCycle(
            isAnnual ? BillingCycle.Monthly : BillingCycle.Annually,
          )
        }
      />
      <Button
        variant="plain"
        font="normalLabel"
        color={isAnnual ? 'black900' : 'black400'}
        onClick={() => props.setBillingCycle(BillingCycle.Annually)}
      >
        Billed annually
      </Button>
      <BestDealBadge active={isAnnual} />
    </Row>
  )
}

const PricingCardContainer = (props: {
  currency: PricingCurrency
  billingCycle: BillingCycle
}) => {
  const { currency, billingCycle } = props
  const moduleTiers = pricingTiers.filter(tier => tier.cardStyle === 'module')
  const highlightTier = pricingTiers.find(
    tier => tier.cardStyle === 'highlight',
  )!
  const otherPlans = pricingTiers.filter(
    tier => tier.cardStyle !== 'module' && tier.cardStyle !== 'highlight',
  )

  return (
    <Box marginX={24}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={{ xs: 16, lg: 24 }}
      >
        <Box maxWidth={{ xs: '100%', lg: 337 }} order={{ xs: 0, lg: 1 }}>
          <CardContainer title="Get everything">
            <Box height="100%">
              <PricingCard
                tier={highlightTier}
                currency={currency}
                billingCycle={billingCycle}
              />
            </Box>
          </CardContainer>
        </Box>
        <CardContainer title="Choose a single module">
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            marginBottom={24}
          >
            <Box marginLeft={12} marginBottom={{ xs: 8, md: 0 }}>
              <TierPrice
                tier={moduleTiers.at(0)!}
                currency={currency}
                billingCycle={billingCycle}
              />
            </Box>
            <Box
              font="small"
              fontSize={14}
              maxWidth={{ xs: '100%', md: 255 }}
              borderLeftColor="gray200"
              borderLeftStyle="solid"
              // @ts-ignore this works
              borderLeftWidth={{ xs: 0, md: 1 }}
              marginTop={{ xs: 0, md: 24 }}
              marginLeft={{ xs: 0, md: 16 }}
              paddingLeft={{ xs: 0, md: 24 }}
            >
              Start with one of the modules. Upgrade at any time to access all
              three.
            </Box>
          </Box>
          <Box
            marginTop="auto"
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={{ xs: 16, lg: 24 }}
          >
            {moduleTiers.map(tier => (
              <PricingCard
                key={tier.title}
                tier={tier}
                currency={currency}
                billingCycle={billingCycle}
              />
            ))}
          </Box>
        </CardContainer>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={{ xs: 16, lg: 24 }}
        marginTop={{ xs: 16, lg: 24 }}
      >
        {otherPlans.map(tier => (
          <PricingCard
            key={tier.title}
            tier={tier}
            currency={currency}
            billingCycle={billingCycle}
          />
        ))}
      </Box>
    </Box>
  )
}

const CardContainer = (props: { title: string; children: ReactNode }) => {
  const { title, children } = props
  return (
    <Box paddingTop={38 as SpaceScaleValue} height="100%">
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        position="relative"
        borderColor="gray200"
        borderTopRightRadius={32}
        borderBottomLeftRadius={32}
        borderBottomRightRadius={32}
        padding={16}
        backgroundColor="white"
        boxShadow="small"
      >
        <Box
          position="absolute"
          height={38}
          top={-38}
          left={-1}
          paddingTop={16}
          paddingX={20}
          font="small"
          fontWeight={700}
          borderColor="gray200"
          borderBottomColor="white"
          backgroundColor="white"
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
        >
          {title}
        </Box>
        {children}
      </Box>
    </Box>
  )
}

const PricingPage = () => {
  const currency = useCurrency()
  return currency !== undefined ? (
    <PricingPageInternal currency={currency ?? 'USD'} />
  ) : null
}

export default PricingPage
