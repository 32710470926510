import React from 'react'
import Box from './primitives/Box'

export default function CheckMarkIcon() {
  return (
    <Box width={24} height={24}>
      <svg
        fill="none"
        height="24"
        viewBox="0 0 25 24"
        width="25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect fill="#34ae73" height="22" rx="11" width="22" x="1.5" y="1" />
        <path
          d="m7.94656 12.2975 3.54024 3.7059 5.8498-8.55542"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </Box>
  )
}
